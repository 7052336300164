import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableComponent } from 'app/layout/common/table/table.component';
import { MaterialModule } from 'app/material.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NumberDirective } from 'app/core/utils/pipe/only-number.pipe';
import { CountryFilterPipe } from './country-filter.pipe';
import { StateFilterPipe } from './state-filter.pipe';
import { CounterCharactersPipe } from './counter.pipe';

@NgModule({
	declarations: [
		TableComponent,
		NumberDirective,
		CountryFilterPipe,
		StateFilterPipe,
		CounterCharactersPipe,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		InfiniteScrollModule,
	],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		TableComponent,
		MaterialModule,
		InfiniteScrollModule,
		NumberDirective,
		CountryFilterPipe,
		StateFilterPipe,
		CounterCharactersPipe,
	],
})
export class SharedModule {}
