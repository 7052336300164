import {
	Component,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import {
	FuseNavigationService,
	FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { AuthService } from 'app/services/auth.service';
import { IBasicUserDetails } from 'app/core/interfaces/IUser';
import { DialogComponent } from 'app/layout/common/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NotificationService } from 'app/services/notification.service';
import { SettingService } from 'app/services/settings.service';

@Component({
	selector: 'classy-layout',
	templateUrl: './classy.component.html',
	styleUrls: ['./classy.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
	expirePlanType = '';
	isSetting: boolean;
	isScreenSmall: boolean;
	navigation: Navigation;
	dialogRef;
	isSubscribed = true;
	freePlanDays = 0;
	@ViewChild('upgradePlan') private upgradePlan: TemplateRef<any>;
	isUpgradePopUp = false;
	user: IBasicUserDetails = this._authService.getUser();
	private _unsubscribeAll: Subject<any> = new Subject<any>();

	/**
	 * Constructor
	 */
	constructor(
		private _navigationService: NavigationService,
		private _fuseMediaWatcherService: FuseMediaWatcherService,
		private _fuseNavigationService: FuseNavigationService,
		private _authService: AuthService,
		private dialog: MatDialog,
		private router: Router,
		private notificationService: NotificationService,
		private settingService: SettingService
	) {}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Getter for current year
	 */
	get currentYear(): number {
		return new Date().getFullYear();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		this._authService.user$.subscribe((user) => {
			this.user = user;
		});
		this._authService.isSubscribed.subscribe((isSubscribed) => {
			this.isSubscribed = isSubscribed;
		});
		if (!this.user) {
			this.user = JSON.parse(localStorage.getItem('user'));
		}
		// Subscribe to navigation data
		this._navigationService.navigation$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((navigation: Navigation) => {
				this.navigation = navigation;
			});
		// Subscribe to media changes
		this._fuseMediaWatcherService.onMediaChange$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(({ matchingAliases }) => {
				// Check if the screen is small
				this.isScreenSmall = !matchingAliases.includes('md');
			});
		this._authService.getUserByToken().subscribe({
			next: (res) => {
				this.freePlanDays = this.daysUntilExpiration(
					res.data?.subscriptionExpiresAt,
					res.data?.subscriptionPlan
				);
				if (res.data?.subscriptionPlan === 'free' || this.expirePlanType) {
					this.isSubscribed = false;
					this._authService.setPlanType(false);
				} else {
					this.isSubscribed = true;
					this._authService.setPlanType(true);
				}
			},
			error: (err) => {
				if (err.error.code === 'TRIAL_OVER') {
					this.expirePlanType = 'free';
					this.openUpgradePlan(this.upgradePlan);
				} else {
					this.notificationService.sendNotification(
						'error',
						err.error.message || 'Internal server error'
					);
				}
			},
		});
		this.settingService.isPaymentDone.subscribe((isPaymentDone) => {
			this.expirePlanType = '';
		});
	}

	daysUntilExpiration(expirationDate, planType) {
		const oneDayInMs = 1000 * 60 * 60 * 24;
		const now = new Date();
		const expiration = new Date(expirationDate);
		const diffInMs = expiration.getTime() - now.getTime();
		const diffInDays = Math.ceil(diffInMs / oneDayInMs);
		if (diffInDays <= 0 && planType === 'free') {
			this.expirePlanType = 'free';
			this.openUpgradePlan(this.upgradePlan);
		} else if (diffInDays <= 0 && planType === 'pro') {
			this.expirePlanType = 'pro';
			this.openUpgradePlan(this.upgradePlan);
		}
		return diffInDays;
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Toggle navigation
	 *
	 * @param name
	 */
	toggleNavigation(name: string): void {
		// Get the navigation
		const navigation =
			this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
				name
			);

		if (navigation) {
			// Toggle the opened status
			navigation.toggle();
		}
	}

	openUpgradePlan(template) {
		this.isUpgradePopUp = true;
		this.dialogRef = this.dialog.open(DialogComponent, {
			data: {
				template: template,
				freeTrialOver: this.expirePlanType === 'free',
			},
			height: 'auto',
			width: '32vw',
			disableClose: this.expirePlanType === 'free',
		});
	}

	goToSubscription() {
		this.router.navigate(['apps', 'settings'], {
			queryParams: { tab: 'subscription' },
		});
		if (this.isUpgradePopUp) {
			this.dialogRef.close();
		}
	}
	openHelp() {
		window.open('https://help.leadmastro.com/', '_blank');
	}
}
