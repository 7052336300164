import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseScrollbarModule } from '@fuse/directives/scrollbar/public-api';
import { FuseVerticalNavigationAsideItemComponent } from '@fuse/components/navigation/vertical/components/aside/aside.component';
import { FuseVerticalNavigationBasicItemComponent } from '@fuse/components/navigation/vertical/components/basic/basic.component';
import { FuseVerticalNavigationCollapsableItemComponent } from '@fuse/components/navigation/vertical/components/collapsable/collapsable.component';
import { FuseVerticalNavigationDividerItemComponent } from '@fuse/components/navigation/vertical/components/divider/divider.component';
import { FuseVerticalNavigationGroupItemComponent } from '@fuse/components/navigation/vertical/components/group/group.component';
import { FuseVerticalNavigationSpacerItemComponent } from '@fuse/components/navigation/vertical/components/spacer/spacer.component';
import { FuseVerticalNavigationComponent } from '@fuse/components/navigation/vertical/vertical.component';
import { NgApexchartsModule } from 'ng-apexcharts';

@NgModule({
	declarations: [
		FuseVerticalNavigationAsideItemComponent,
		FuseVerticalNavigationBasicItemComponent,
		FuseVerticalNavigationCollapsableItemComponent,
		FuseVerticalNavigationDividerItemComponent,
		FuseVerticalNavigationGroupItemComponent,
		FuseVerticalNavigationSpacerItemComponent,
		FuseVerticalNavigationComponent,
	],
	imports: [
		CommonModule,
		RouterModule,
		MatButtonModule,
		MatDividerModule,
		MatIconModule,
		MatMenuModule,
		MatTooltipModule,
		FuseScrollbarModule,
		NgApexchartsModule,
	],
	exports: [FuseVerticalNavigationComponent],
})
export class FuseNavigationModule {}
