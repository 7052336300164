import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
	// Redirect empty path to '/dashboards/project'
	{ path: '', pathMatch: 'full', redirectTo: 'dashboards/analytics' },

	// Redirect signed-in user to the '/dashboards/project'
	//
	// After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
	// path. Below is another redirection for that path to redirect the user to the desired
	// location. This is a small convenience to keep all main routes together here on this file.
	{
		path: 'signed-in-redirect',
		pathMatch: 'full',
		redirectTo: 'dashboards/analytics',
	},

	// Auth routes for guests
	{
		path: '',
		// canMatch: [NoAuthGuard],
		component: LayoutComponent,
		data: {
			layout: 'empty',
		},
		children: [
			{
				path: 'confirmation-required',
				loadChildren: () =>
					import(
						'app/modules/auth/confirmation-required/confirmation-required.module'
					).then((m) => m.AuthConfirmationRequiredModule),
			},
			{
				path: 'forgot-password',
				loadChildren: () =>
					import(
						'app/modules/auth/forgot-password/forgot-password.module'
					).then((m) => m.AuthForgotPasswordModule),
			},
			{
				path: 'reset-password',
				loadChildren: () =>
					import('app/modules/auth/reset-password/reset-password.module').then(
						(m) => m.AuthResetPasswordModule
					),
			},
			{
				path: 'sign-in',
				loadChildren: () =>
					import('app/modules/auth/sign-in/sign-in.module').then(
						(m) => m.AuthSignInModule
					),
			},
			{
				path: 'sign-up',
				loadChildren: () =>
					import('app/modules/auth/sign-up/sign-up.module').then(
						(m) => m.AuthSignUpModule
					),
			},
		],
	},

	// Admin routes
	// TODO: need to handle AuthGuard properly somethime it's not working properly
	{
		path: '',
		canMatch: [AuthGuard],
		component: LayoutComponent,
		resolve: {
			initialData: InitialDataResolver,
		},
		children: [
			// Dashboards
			{
				path: 'dashboards',
				children: [
					{
						path: 'analytics',
						loadChildren: () =>
							import(
								'app/modules/admin/dashboards/analytics/analytics.module'
							).then((m) => m.AnalyticsModule),
					},
				],
			},

			// Apps
			{
				path: 'apps',
				children: [
					{
						path: '',
						loadChildren: () =>
							import('app/modules/admin/apps/apps.module').then(
								(m) => m.AppsModule
							),
					},
				],
			},

			// Pages
			{
				path: 'pages',
				children: [
					// Error
					{
						path: 'error',
						children: [
							{
								path: '404',
								loadChildren: () =>
									import(
										'app/modules/admin/pages/error/error-404/error-404.module'
									).then((m) => m.Error404Module),
							},
							{
								path: '500',
								loadChildren: () =>
									import(
										'app/modules/admin/pages/error/error-500/error-500.module'
									).then((m) => m.Error500Module),
							},
						],
					},

					// Invoice
					{
						path: 'invoice',
						children: [
							{
								path: 'printable',
								children: [
									{
										path: 'compact',
										loadChildren: () =>
											import(
												'app/modules/admin/pages/invoice/printable/compact/compact.module'
											).then((m) => m.CompactModule),
									},
									{
										path: 'modern',
										loadChildren: () =>
											import(
												'app/modules/admin/pages/invoice/printable/modern/modern.module'
											).then((m) => m.ModernModule),
									},
								],
							},
						],
					},
					// Pricing
					{
						path: 'pricing',
						children: [
							{
								path: 'modern',
								loadChildren: () =>
									import(
										'app/modules/admin/pages/pricing/modern/modern.module'
									).then((m) => m.PricingModernModule),
							},
							{
								path: 'simple',
								loadChildren: () =>
									import(
										'app/modules/admin/pages/pricing/simple/simple.module'
									).then((m) => m.PricingSimpleModule),
							},
							{
								path: 'single',
								loadChildren: () =>
									import(
										'app/modules/admin/pages/pricing/single/single.module'
									).then((m) => m.PricingSingleModule),
							},
							{
								path: 'table',
								loadChildren: () =>
									import(
										'app/modules/admin/pages/pricing/table/table.module'
									).then((m) => m.PricingTableModule),
							},
						],
					},

					// Settings
					{
						path: 'settings',
						loadChildren: () =>
							import('app/modules/admin/apps/settings/settings.module').then(
								(m) => m.SettingsModule
							),
					},
				],
			},

			// 404 & Catch all
			{
				path: '404-not-found',
				pathMatch: 'full',
				loadChildren: () =>
					import(
						'app/modules/admin/pages/error/error-404/error-404.module'
					).then((m) => m.Error404Module),
			},
			{ path: '**', redirectTo: '404-not-found' },
		],
	},
];
