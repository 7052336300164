import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Resolve,
	RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { NavigationService } from 'app/core/navigation/navigation.service';

@Injectable({
	providedIn: 'root',
})
export class InitialDataResolver implements Resolve<any> {
	/**
	 * Constructor
	 */
	constructor(private _navigationService: NavigationService) {}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Use this resolver to resolve initial mock-api for the application
	 *
	 * @param route
	 * @param state
	 */
	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<any> {
		return this._navigationService.get();
	}
}
