import { Injectable } from '@angular/core';
import { CanMatch, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { Observable, of } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanMatch {
	/**
	 * Constructor
	 */
	constructor(private _authService: AuthService, private _router: Router) {}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Can match
	 *
	 * @param route
	 * @param segments
	 */
	canMatch(
		route: Route,
		segments: UrlSegment[]
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		return this._check(segments);
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Private methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Check the authenticated status
	 *
	 * @param segments
	 * @private
	 */
	private _check(segments: UrlSegment[]): Observable<boolean | UrlTree> {
		// Check the authentication status
		const redirectURL = `/${segments.join('/')}`;
		const urlTree = this._router.parseUrl(`sign-in?redirectURL=${redirectURL}`);
		const token = this._authService.getToken();
		const user = this._authService.getUser();
		if (token) {
			if (user) {
				return of(true);
			} else {
				this._authService.getUserByToken().subscribe({
					next: (res) => {
						if (res.data) {
							this._authService.setUser(res.data);
							return of(true);
						}
					},
					error: (err) => {
						return of(urlTree);
					},
				});
			}
		} else {
			return of(urlTree);
		}
	}
}
