import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { ITableMeta } from '../../../core/interfaces/ITable';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { AuthService } from 'app/services/auth.service';

@Component({
	selector: 'app-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnChanges {
	@Input() tableData;
	@Input() tableMetaInfo: ITableMeta;
	@Output() rowClicked = new EventEmitter<string>();
	@Output() actionButtonClicked = new EventEmitter<string>();
	@Output() actionItemClicked = new EventEmitter<{
		actionType: string;
		row: object;
	}>();
	@Output() checked = new EventEmitter<any>();
	@Input() isAllSelected;
	@Input() selectedCount;
	isAllChecked = false;
	isSubscribed = true;
	subscription: Subscription;

	matChipDisplayItemCount;

	private _unsubscribeAll: Subject<any> = new Subject<any>();

	constructor(
		private _fuseMediaWatcherService: FuseMediaWatcherService,
		private authService: AuthService
	) {}

	ngOnInit(): void {
		this.subscription = this.authService.getPlanType().subscribe((plan) => {
			this.isSubscribed = plan;
		});
		this._fuseMediaWatcherService.onMediaChange$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(({ matchingAliases }) => {
				// Set the drawerMode if the given breakpoint is active
				if (matchingAliases.includes('xl')) {
					this.matChipDisplayItemCount = 2;
				} else {
					this.matChipDisplayItemCount = 1;
				}
			});
	}

	generateToolTipForMatChip(chipArray) {
		return chipArray.join(',');
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.isAllSelected) {
			this.isAllChecked = changes.isAllSelected.currentValue;
		}
		if (changes.tableData?.currentValue?.length < 1) {
			this.isAllChecked = false;
		} else if (changes.tableData?.currentValue?.length > 0) {
			changes.tableData.currentValue.forEach((val) => {
				if (!val['isChecked']) {
					this.isAllChecked = false;
				}
			});
		}
	}

	hanleClickEvent(row, event) {
		if (this.tableMetaInfo.isClickable) {
			this.rowClicked.emit(row);
		}
	}

	setChecked(value) {
		this.selectedCount = 0;
		this.tableData.forEach((row) => {
			row['isChecked'] = value;
			if (value) {
				this.selectedCount++;
			}
		});
		this.checked.emit({
			selectedCount: this.selectedCount,
			isAllSelected: value,
		});
	}

	selectOneRow(value, index) {
		if (value) {
			this.selectedCount++;
		} else if (!value && this.tableData[index].isChecked) {
			this.selectedCount--;
		}
		//Todo: We should not change original data from inside this component
		this.tableData[index].isChecked = value;
		this.checked.emit({
			selectedCount: this.selectedCount,
			isAllSelected: false,
		});
	}
	trackByFn(index: number, item: any): any {
		return item.id || index || item.name;
	}
	actionItemButtonClicked(actionType: string, row: object) {
		this.actionItemClicked.emit({
			actionType,
			row,
		});
	}
}
