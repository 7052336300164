import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { INotification } from 'app/core/interfaces/INotification';
import { IApiResponse, IPaginateResult } from 'app/core/interfaces/common';
import { Urls } from 'app/core/urls';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class PanelNotificationService {
	constructor(private readonly http: HttpClient) {}

	getNotification(
		params
	): Observable<IApiResponse<IPaginateResult<INotification>>> {
		return this.http.get<IApiResponse<IPaginateResult<INotification>>>(
			Urls.notification,
			{ params }
		);
	}

	markAllAsRead() {
		return this.http.patch(Urls.notification, '');
	}

	changeNotificationStatus(notificationId: string, params) {
		return this.http.patch(Urls.notification + '/' + notificationId, '', {
			params,
		});
	}

	deleteNotification(notificationId: string) {
		return this.http.delete(Urls.notification + '/' + notificationId);
	}
}
