import { NgModule } from '@angular/core';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatBadgeModule } from '@angular/material/badge';

@NgModule({
	declarations: [],
	imports: [
		MatButtonModule,
		MatCheckboxModule,
		MatDatepickerModule,
		MatDividerModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatLuxonDateModule,
		MatMenuModule,
		MatProgressBarModule,
		MatRadioModule,
		MatRippleModule,
		MatSelectModule,
		MatSidenavModule,
		MatTableModule,
		MatTooltipModule,
		MatChipsModule,
		MatPaginatorModule,
		DragDropModule,
		MatSnackBarModule,
		MatDialogModule,
		MatButtonToggleModule,
		MatTabsModule,
		MatBadgeModule,
	],
	exports: [
		MatButtonModule,
		MatCheckboxModule,
		MatDatepickerModule,
		MatDividerModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatLuxonDateModule,
		MatMenuModule,
		MatProgressBarModule,
		MatRadioModule,
		MatRippleModule,
		MatSelectModule,
		MatSidenavModule,
		MatTableModule,
		MatTooltipModule,
		MatChipsModule,
		MatPaginatorModule,
		DragDropModule,
		MatSnackBarModule,
		MatDialogModule,
		MatButtonToggleModule,
		MatTabsModule,
		MatBadgeModule,
	],
})
export class MaterialModule {}
