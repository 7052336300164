<div class="snack-bar-container" [ngClass]="[data.class]">
	<!-- Border -->
	<div class="snack-bar-border"></div>

	<!-- Icon -->
	<div class="snack-bar-icon">
		<mat-icon
			*ngIf="data.type === 'info'"
			[svgIcon]="'heroicons_solid:information-circle'"
		></mat-icon>

		<mat-icon
			*ngIf="data.type === 'success'"
			[svgIcon]="'heroicons_solid:check-circle'"
		></mat-icon>

		<mat-icon
			*ngIf="data.type === 'warning'"
			[svgIcon]="'heroicons_solid:exclamation'"
		></mat-icon>

		<mat-icon
			*ngIf="data.type === 'error'"
			[svgIcon]="'heroicons_solid:x-circle'"
		></mat-icon>
	</div>

	<!-- Content -->
	<div class="snack-bar-content">
		<div class="snack-bar-title">
			<span>{{ data.title }}</span>
		</div>

		<div class="snack-bar-message">
			<span>{{ data.message }}</span>
		</div>
	</div>

	<!-- Dismiss button -->
	<button
		class="snack-bar-dismiss-button"
		mat-icon-button
		(click)="snackBarRef.dismiss()"
	>
		<mat-icon [svgIcon]="'heroicons_solid:x'"></mat-icon>
	</button>
</div>
