import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';

@Component({
	standalone: true,
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	imports: [
		CommonModule,
		MatFormFieldModule,
		MatIconModule,
		MatMenuModule,
		MatButtonModule,
		MatInputModule,
	],
})
export class HeaderComponent {
	@Output() searchEvent = new EventEmitter<any>();
	@Output() filterEvent = new EventEmitter<any>();
	@Output() importEvent = new EventEmitter<any>();
	@Output() cornerButtonClicked = new EventEmitter<object>();
	@Output() matMenuClicked = new EventEmitter<object>();
	@Output() allSelected = new EventEmitter<boolean>();
	@Input() headerTitle;
	@Input() headerDescription;
	@Input() matMenu;
	@Input() isMenu;
	@Input() menuIcon = 'heroicons_outline:dots-vertical';
	@Input() isMenuSelected;
	@Input() isFilter;
	@Input() isFilterApply;
	@Input() isSearch: boolean;
	@Input() isImport;
	@Input() isDisplaySelectedItems;
	@Input() isAllSelected;
	@Input() selectedCount;
	@Input() cornerButtons: Array<{
		text: string;
		icon: string;
		iconAfterText?: boolean;
		value?: string;
	}>;
	@Input() importMenus;
	@Input() totalRecords;

	search(value, event?) {
		if (event) {
			if (!event.srcElement.value.trim() && event.keyCode === 8) {
				this.searchEvent.emit('');
				return;
			}
		}
		if (!value.trim()) {
			return;
		}
		if (event) {
			if (event.keyCode === 8 || event.keyCode === 46) {
				if (value.length === 0) {
					this.searchEvent.emit('');
				}
			}
			if (event.keyCode === 13) {
				this.searchEvent.emit(value);
			}
		} else {
			if (value.trim()) {
				this.searchEvent.emit(value);
			} else if (!value.length) {
				this.searchEvent.emit('');
			}
		}
	}

	menuItemClicked(event) {
		this.matMenuClicked.emit(event);
	}

	selectAllRecords() {
		this.isAllSelected = true;
		this.selectedCount = this.totalRecords;
		this.allSelected.emit(this.isAllSelected);
	}

	clearSelection() {
		this.isAllSelected = false;
		this.selectedCount = 0;
		this.allSelected.emit(this.isAllSelected);
	}
}
