import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'countryFilter',
})
export class CountryFilterPipe implements PipeTransform {
	transform(countries: any[], searchTerm: string): any[] {
		if (!countries) {
			return [];
		}
		if (!searchTerm) {
			return countries;
		}
		searchTerm = searchTerm.toLowerCase();
		return countries.filter((country) => {
			return country.name.toLowerCase().includes(searchTerm);
		});
	}
}
