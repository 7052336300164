import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	IEvents,
	IGroups,
	IList,
	IProspect,
	ITag,
} from 'app/core/interfaces/IProspect';
import { IApiResponse, IPaginateResult } from 'app/core/interfaces/common';
import { Urls } from 'app/core/urls';
import { Observable } from 'rxjs';
import { ICrmData } from 'app/core/interfaces/ICrm';

@Injectable({
	providedIn: 'root',
})
export class ProspectsService {
	constructor(private readonly http: HttpClient) {}

	createList(listObject) {
		return this.http.post(`${Urls.list}`, listObject);
	}

	getList(params): Observable<IApiResponse<IPaginateResult<IList>>> {
		return this.http.get<IApiResponse<IPaginateResult<IList>>>(`${Urls.list}`, {
			params,
		});
	}

	getAllList(): Observable<IApiResponse<Array<IList>>> {
		return this.http.get<IApiResponse<Array<IList>>>(`${Urls.list}/all-Lists`);
	}

	editList(listId, listObject) {
		return this.http.patch(`${Urls.list}/${listId}`, listObject);
	}

	deleteList(listId) {
		return this.http.delete(`${Urls.list}/${listId}`);
	}

	getListProspects(
		params
	): Observable<IApiResponse<IPaginateResult<IProspect>>> {
		return this.http.get<IApiResponse<IPaginateResult<IProspect>>>(
			`${Urls.listProfiles}`,
			{ params }
		);
	}

	deleteProspects(params) {
		return this.http.delete(Urls.listProfiles, { params });
	}

	getUserGroups(): Observable<IApiResponse<IPaginateResult<IGroups>>> {
		return this.http.get<IApiResponse<IPaginateResult<IGroups>>>(
			`${Urls.processManagementBaseUrl}groups`
		);
	}

	getUserEvents(): Observable<IApiResponse<IPaginateResult<IEvents>>> {
		return this.http.get<IApiResponse<IPaginateResult<IEvents>>>(
			`${Urls.processManagementBaseUrl}events`
		);
	}

	addTagsToProfiles(tagProfiles) {
		return this.http.patch(`${Urls.listProfiles}/tags`, tagProfiles);
	}

	addTagToSingleProfile(profileId, tags) {
		return this.http.patch(`${Urls.listProfiles}/tags/${profileId}`, tags);
	}

	addTag(tagObject) {
		return this.http.post(`${Urls.tags}`, tagObject);
	}

	updateTag(tagId, tag) {
		return this.http.patch(`${Urls.tags}/${tagId}`, tag);
	}

	getTags(params): Observable<IApiResponse<Array<ITag>>> {
		return this.http.get<IApiResponse<Array<ITag>>>(`${Urls.tags}`, { params });
	}

	deleteTag(tagId) {
		return this.http.delete(`${Urls.tags}/${tagId}`);
	}

	addToCrm(crmObject: ICrmData) {
		return this.http.post<ICrmData>(`${Urls.crm}`, crmObject);
	}
}
