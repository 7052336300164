import { NgModule } from '@angular/core';
import { LayoutComponent } from 'app/layout/layout.component';
import { ClassyLayoutModule } from 'app/layout/layouts/vertical/classy/classy.module';
import { SharedModule } from 'app/shared/shared.module';
import { EmptyLayoutModule } from './layouts/empty/empty.module';
import { DialogComponent } from './common/dialog/dialog.component';
import { SnackBarComponent } from './common/snack-bar/snack-bar.component';

const layoutModules = [
	EmptyLayoutModule,
	// Vertical navigation
	ClassyLayoutModule,
];

@NgModule({
	declarations: [LayoutComponent, DialogComponent, SnackBarComponent],
	imports: [SharedModule, ...layoutModules],
	exports: [LayoutComponent, ...layoutModules],
})
export class LayoutModule {}
