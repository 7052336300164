import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpErrorResponse,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	constructor(private route: Router) {}

	intercept(
		request: HttpRequest<unknown>,
		next: HttpHandler
	): Observable<HttpEvent<unknown>> {
		request = request.clone({
			setHeaders: {
				authorization: `${localStorage.getItem('token')}`,
			},
		});
		return next.handle(request).pipe(
			tap(
				(next) => {
					return;
				},
				(err: any) => {
					if (
						!this.route.routerState.snapshot.url.includes('sign-in') &&
						err instanceof HttpErrorResponse
					) {
						if (err.status === 401 && err.error?.code === 'TOKEN_EXPIRED') {
							location.href = '';
							localStorage.removeItem('token');
							localStorage.removeItem('user');
							location.href = '/sign-in';
						}
					}
				}
			)
		);
	}
}
