<div
	class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto"
	[ngClass]="!isSubscribed ? 'subscription-header-table' : 'common-table'"
>
	<table class="grid">
		<thead
			class="items-center z-10 sticky top-0 grid gap-4 py-2 px-6 md:px-8 shadow text-md text-secondary bg-gray-50 dark:bg-black dark:bg-opacity-5"
		>
			<tr class="flex items-center">
				<th class="flex items-center" *ngIf="tableMetaInfo.isCheckBox">
					<mat-checkbox
						(ngModelChange)="setChecked($event)"
						[(ngModel)]="isAllChecked"
						(click)="$event.stopPropagation()"
						class="flex items-center h-5 min-h-10"
						[color]="'primary'"
						[checked]="false"
					>
					</mat-checkbox>
				</th>
				<th
					*ngFor="let row of tableMetaInfo.rows; trackBy: trackByFn"
					[width]="row.width + '%'"
				>
					<div
						class="flex"
						[class.justify-center]="row.align === 'CENTER'"
						[class.justify-end]="row.align === 'RIGHT'"
					>
						{{ row.name }}
						<img [src]="row.sortIcon" *ngIf="row.sortIcon" class="c-p ml-5" />
					</div>
				</th>
				<!-- <th *ngIf="table.actions.length > 0">Action</th> -->
			</tr>
		</thead>
		<tbody>
			<!-- Prospects -->
			<tr
				*ngFor="let rule of tableData; let dIndex = index; trackBy: trackByFn"
				class="z-20 flex items-center px-6 py-2 md:px-8 cursor-pointer border-b"
			>
				<td *ngIf="tableMetaInfo.isCheckBox">
					<mat-checkbox
						[ngModel]="rule['isChecked']"
						(ngModelChange)="selectOneRow($event, dIndex)"
						(click)="$event.stopPropagation()"
						class="flex items-center h-5 min-h-10"
						[color]="'primary'"
						[checked]="false"
					>
					</mat-checkbox>
				</td>
				<td
					*ngFor="let row of tableMetaInfo.rows; trackBy: trackByFn"
					[width]="row.width + '%'"
					class="truncate flex"
					[class.justify-center]="row.align === 'CENTER'"
					[class.justify-end]="row.align === 'RIGHT'"
					(click)="!row.isActionMenu ? hanleClickEvent(rule, $event) : {}"
					#col
				>
					<div class="flex" *ngIf="row.type === 'textWithSubTitle'">
						<div
							class="flex flex-0 items-center justify-center w-10 h-10 rounded-full overflow-hidden"
						>
							<ng-container *ngIf="rule[row.image]">
								<img
									class="object-cover w-full h-full"
									[src]="rule[row.image]"
									alt="Contact avatar"
								/>
							</ng-container>
							<ng-container *ngIf="!rule[row.image]">
								<div
									class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200"
								>
									{{ rule[row.value].charAt(0) }}
								</div>
							</ng-container>
						</div>
						<div class="min-w-0 ml-4">
							<div class="font-medium leading-5 truncate">
								{{ rule[row.value] }}
							</div>
							<div
								[style.max-width.px]="col.offsetWidth - 60"
								class="leading-5 truncate text-secondary"
							>
								{{ rule[row.subValue] }}
							</div>
						</div>
					</div>
					<div class="flex items-center" *ngIf="row.type === 'statues'">
						<ng-container *ngFor="let status of row.statues">
							<mat-icon
								class="mr-1"
								[svgIcon]="status.icon"
								[matTooltip]="status.toolTip"
								matTooltipPosition="above"
							></mat-icon>
						</ng-container>
					</div>
					<div *ngIf="row.type === 'matChips'">
						<mat-chip-listbox class="mat-chip-list">
							<ng-container *ngIf="rule[row.value]?.length">
								<mat-chip
									*ngFor="
										let chip of rule[row.value]?.slice(
											0,
											matChipDisplayItemCount
										)
									"
									[matTooltip]="chip"
									[matTooltipPosition]="'above'"
									class="mat-chip-text"
									>{{ chip }}
								</mat-chip>
								<mat-chip
									*ngIf="rule[row.value]?.length - matChipDisplayItemCount > 0"
									[matTooltip]="generateToolTipForMatChip(rule[row.value])"
									[matTooltipPosition]="'above'"
									>+{{ rule[row.value]?.length - matChipDisplayItemCount }}
								</mat-chip>
							</ng-container>
							<mat-chip *ngIf="!rule[row.value]?.length"> No Tag </mat-chip>
						</mat-chip-listbox>
					</div>
					<!--Simple Text-->
					<div class="flex w-full" *ngIf="row.type === 'text'">
						<div class="min-w-0 w-full" #outer>
							<div
								[style.max-width.px]="outer.offsetWidth - 10"
								class="font-medium leading-5 truncate"
							>
								{{ rule[row.value] }}
							</div>
						</div>
					</div>
					<!--Campaign fields-->
					<div class="flex" *ngIf="row.type === 'statues-chip'">
						<span
							[class]="
								'px-5 min-h-[30px] rounded-full items-center flex align-center w-max text-sm status-' +
								rule[row.value]?.toUpperCase()
							"
						>
							<div class="min-h-[8px] min-w-[8px] rounded-full mr-2"></div>
							<span>{{ rule[row.value] | titlecase }}</span>
						</span>
					</div>
					<!--Campaign fields-->
					<div class="flex" *ngIf="row.type === 'prospectCount'">
						<div
							class="px-4 rounded-full flex items-center justify-between prospect-count-chip"
						>
							<mat-icon
								svgIcon="heroicons_outline:user-group"
								class="icon-size-4 text-primary"
							></mat-icon>
							<span>{{ rule[row.value] }}</span>
						</div>
					</div>
					<!--Generic action menu -->
					<div
						class="flex items-center justify-between action-menu"
						*ngIf="row.type === 'action'"
					>
						<mat-icon
							svgIcon="heroicons_outline:dots-vertical"
							[matMenuTriggerFor]="actionMenu"
							class="action-menu-icon"
						></mat-icon>
						<mat-menu #actionMenu="matMenu" class="table-action">
							<button
								*ngFor="let menu of row.value"
								mat-menu-item
								(click)="actionItemButtonClicked(menu.value, rule)"
							>
								<mat-icon [svgIcon]="menu.icon"></mat-icon>
								<span class="action-menu-text font-medium">{{
									menu.text
								}}</span>
							</button>
						</mat-menu>
					</div>
					<!--Single action-->
					<div *ngIf="row.type === 'singleAction'" class="flex items-center">
						<ng-container>
							<button
								(click)="
									actionButtonClicked.emit(rule); $event.stopPropagation()
								"
							>
								<mat-icon
									[matTooltip]="row.singleAction.toolTip"
									[svgIcon]="row.singleAction.icon"
									class="mr-1"
									matTooltipPosition="above"
								></mat-icon>
							</button>
						</ng-container>
					</div>
					<!--Campaign action menu -->
					<div
						class="flex items-center justify-between action-menu"
						*ngIf="row.type === 'campaignAction'"
					>
						<mat-icon
							svgIcon="heroicons_outline:dots-vertical"
							[matMenuTriggerFor]="actionMenu"
							[class.screen__blur]="!rule[row.value]?.length"
							class="action-menu-icon"
						></mat-icon>
						<mat-menu #actionMenu="matMenu" class="table-action">
							<button
								*ngFor="let menu of rule[row.value]"
								mat-menu-item
								(click)="actionItemButtonClicked(menu.value, rule)"
							>
								<mat-icon [svgIcon]="menu.icon"></mat-icon>
								<span class="action-menu-text font-medium">{{
									menu.text
								}}</span>
							</button>
						</mat-menu>
					</div>
				</td>
			</tr>
		</tbody>
	</table>
</div>
