import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBasicUserDetails } from 'app/core/interfaces/IUser';
import { IApiResponse } from 'app/core/interfaces/common';
import { Urls } from 'app/core/urls';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	public isSubscribed = new BehaviorSubject<any>(null);
	public profileDetails = new BehaviorSubject<any>(null);
	private user = new BehaviorSubject<any>(null);
	constructor(private readonly http: HttpClient) {}

	getToken() {
		return localStorage.getItem('token');
	}

	getUser(): IBasicUserDetails {
		const user = localStorage.getItem('user');
		if (user) {
			return JSON.parse(user);
		}
	}

	get user$(): Observable<any> {
		return this.user.asObservable();
	}

	setPlanType(planType: boolean) {
		this.isSubscribed.next(planType);
	}

	getPlanType(): Observable<boolean> {
		return this.isSubscribed.asObservable();
	}

	setUser(user) {
		this.user.next(user);
		localStorage.setItem('user', JSON.stringify(user));
	}

	getUserByToken(): Observable<IApiResponse<IBasicUserDetails>> {
		return this.http.get<IApiResponse<IBasicUserDetails>>(`${Urls.user}/me`);
	}
}
