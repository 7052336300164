import { environment } from '../../environments/environment';
export class Urls {
	static processManagementBaseUrl = `${environment.processManagementApiUrl}/`;
	static invoiceManagementBaseUrl = `${environment.invoiceManagementApiUrl}/`;
	static crmManagementBaseUrl = `${environment.crmManagementApiUrl}/`;
	static tokenManagementBaseUrl = `${environment.authExchangeUrl}/`;

	static list = `${Urls.processManagementBaseUrl}lists`;

	static user = `${Urls.processManagementBaseUrl}users`;
	static billingDetails = `${Urls.invoiceManagementBaseUrl}billing-details`;
	static upgradePlan = `${Urls.invoiceManagementBaseUrl}subscriptions`;
	static tokenExchange = `${Urls.tokenManagementBaseUrl}auth/generate-token`;

	static listProfiles = `${Urls.processManagementBaseUrl}list-profiles`;
	static campaigns = `${Urls.processManagementBaseUrl}campaigns`;
	static crm = `${Urls.crmManagementBaseUrl}leads`;
	static importProcesses = `${Urls.processManagementBaseUrl}import-processes`;
	static tagProfile = `${Urls.processManagementBaseUrl}tag-profiles`;
	static tags = `${Urls.processManagementBaseUrl}tags`;
	static messageTemplate = `${Urls.processManagementBaseUrl}message-templates`;
	static invitationTemplate = `${Urls.processManagementBaseUrl}invitation-templates`;
	static invoice = `${Urls.invoiceManagementBaseUrl}invoices`;
	static subscription = `${Urls.invoiceManagementBaseUrl}subscriptions`;
	static notification = `${Urls.processManagementBaseUrl}notifications`;
}
