<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
	class="print:hidden classy-background"
	[mode]="isScreenSmall ? 'over' : 'side'"
	[mode]="isSetting ? 'over' : 'side'"
	[name]="'mainNavigation'"
	[navigation]="navigation.default"
	[opened]="!isScreenSmall"
	[freeTrialOver]="expirePlanType === 'free' || expirePlanType === 'pro'"
>
	<!-- Navigation header hook -->
	<ng-container fuseVerticalNavigationContentHeader>
		<div class="flex items-center w-full mb-4 p-4 pl-6 justify-center">
			<!-- Logo -->
			<div class="flex items-center justify-center">
				<img class="lead_masto_logo" src="assets/images/lead_mastro_logo.svg" />
			</div>
		</div>
		<!-- User -->
		<div class="flex flex-col items-center mb-14 w-full p-4">
			<div class="relative w-24 h-24">
				<img
					class="w-full h-full rounded-full"
					*ngIf="user?.profileImage"
					[src]="user?.profileImage"
					alt="User avatar"
				/>
				<mat-icon
					class="icon-size-24"
					*ngIf="!user?.profileImage"
					[svgIcon]="'heroicons_solid:user-circle'"
				></mat-icon>
			</div>
			<div
				*ngIf="user?.firstName"
				class="flex flex-col items-center justify-center w-full mt-6"
			>
				<div
					class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium"
				>
					{{ user?.firstName }} {{ user?.lastName }}
				</div>
				<div
					*ngIf="user?.email"
					class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary"
				>
					{{ user?.email }}
				</div>
			</div>
		</div>
	</ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
	<!-- Header -->
	<div
		*ngIf="!isSubscribed"
		class="relative flex flex-0 items-center w-full h-12 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b dark:bg-transparent print:hidden subscription-header"
	>
		<mat-icon

			class="icon-size-5 mr-2"
		svgIcon="heroicons_outline:information-circle"></mat-icon>
		<div *ngIf="!expirePlanType.length" class="mr-1 free-trial">
			You've {{ freePlanDays }} days remaining of your free trial.
		</div>
		<div *ngIf="expirePlanType === 'free'" class="mr-1 free-trial">
			Your free trial has been over.
		</div>
		<div *ngIf="expirePlanType === 'pro'" class="mr-1 free-trial">
			Your subscription has been expired.
		</div>
		<div
			(click)="goToSubscription()"class="text-xl font-semibold pro-plan cursor-pointer"
			>
			Upgrade to Pro
		</div>
	</div>
	<div
		class="relative flex flex-0 items-center w-full h-12 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
	>
		<!-- Navigation toggle button -->
		<button (click)="toggleNavigation('mainNavigation')" mat-icon-button>
			<mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
		</button>

		<!-- Components -->
		<div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
			<!-- Help button -->
			<button mat-icon-button matTooltip="Help" (click)="openHelp()">
				<mat-icon
					[svgIcon]="'heroicons_outline:question-mark-circle'"
				></mat-icon>
			</button>
			<notifications  matTooltip="Notifications"></notifications>
		</div>
	</div>

	<!-- Content -->
	<div class="flex flex-col flex-auto">
		<!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
		<router-outlet *ngIf="true"></router-outlet>
	</div>

	<!-- Footer  -->
	<!-- <div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div> -->
</div>

<ng-template #upgradePlan>
	<div class="flex flex-col flex-auto items-center">
		<img src="assets/images/Upgrade.svg" />
		<div
			*ngIf="expirePlanType === 'free'"
			class="mt-7 text-center text-base upgrade-text"
		>
			Your free trial has expired. To continue sending the invites and messages
			on LinkedIn, upgrade your account to a pro plan.
		</div>
		<div
			*ngIf="expirePlanType === 'pro'"
			class="mt-7 text-center text-base upgrade-text"
		>
			Your subscription has expired. To continue sending the invites and
			messages on LinkedIn, upgrade your account to a pro plan.
		</div>
		<button (click)="goToSubscription()" class="upgrade-btn mt-5 mb-2">
			<span class="text-xl">Upgrade</span>
		</button>
	</div>
</ng-template>
