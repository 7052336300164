<div class="flex justify-between w-full">
	<div class="leading-none">
		<span class="text-4xl font-extrabold tracking-tight mr-6">{{
			headerTitle
		}}</span>
		<br />
		<span *ngIf="headerDescription" class="mt-2 block header-description">{{
			headerDescription
		}}</span>
		<div
			class="ml-0.5 mt-1 font-medium text-secondary"
			*ngIf="(selectedCount > 0 || isAllSelected) && isDisplaySelectedItems"
		>
			All {{ selectedCount }} records are selected.
			<span
				class="checked-count"
				(click)="selectAllRecords()"
				*ngIf="totalRecords !== selectedCount"
				>{{ 'Select All ' + totalRecords }}</span
			>
			<span
				class="checked-count"
				*ngIf="totalRecords === selectedCount"
				(click)="clearSelection()"
				>Clear Selection</span
			>
		</div>
	</div>
	<div class="flex items-center justify-center">
		<div class="search-box" *ngIf="isSearch">
			<mat-form-field
				class="fuse-mat-dense w-full min-w-50"
				subscriptSizing="dynamic"
			>
				<mat-icon
					class="icon-size-5"
					matPrefix
					[svgIcon]="'heroicons_solid:search'"
				></mat-icon>
				<input
					matInput
					(keyup)="search(searchInput.value, $event)"
					#searchInput
					[autocomplete]="'off'"
					[placeholder]="'Search'"
				/>
			</mat-form-field>
		</div>
		<div
			*ngIf="isMenu"
			class="common-mat-button ml-2 cursor-pointer"
			[matMenuTriggerFor]="menu"
			[class.screen__blur]="
				isDisplaySelectedItems && !isAllSelected && selectedCount <= 0
			"
		>
			<ng-container *ngIf="isMenuSelected">
				<span
					class="absolute flex items-center justify-center transform translate-x-2 -translate-y-6 h-0"
				>
					<span
						class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 bg-indigo-700 rounded-full text-indigo-50 text-xs font-medium"
					>
					</span>
				</span>
			</ng-container>
			<mat-icon [svgIcon]="menuIcon"></mat-icon>
		</div>
		<div
			*ngIf="isFilter"
			class="common-mat-button ml-2 cursor-pointer flex flex-col"
			(click)="filterEvent.emit()"
		>
			<ng-container *ngIf="isFilterApply">
				<span
					class="flex items-center justify-center transform translate-x-2 -translate-y-3 h-0"
				>
					<span
						class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 bg-indigo-700 rounded-full text-indigo-50 text-xs font-medium"
					>
					</span>
				</span>
			</ng-container>
			<mat-icon svgIcon="mat_outline:filter_list"></mat-icon>
		</div>
		<button
			*ngIf="isImport"
			mat-flat-button
			[color]="'primary'"
			class="ml-4"
			[matMenuTriggerFor]="importMenu"
		>
			<span class="inline-flex items-center">
				<mat-icon svgIcon="heroicons_outline:upload"></mat-icon>
				<span class="ml-2 mr-1">Import</span>
			</span>
		</button>

		<button
			*ngFor="let button of cornerButtons"
			class="ml-4 header-btn"
			mat-flat-button
			[color]="'primary'"
			(click)="
				cornerButtonClicked.emit({ text: button.text, value: button.value })
			"
			[ngClass]="{ 'icon-only': !button.text }"
		>
			<span class="inline-flex items-center">
				<mat-icon
					[svgIcon]="button.icon"
					*ngIf="!button.iconAfterText"
				></mat-icon>
				<span *ngIf="button.text" class="ml-2 mr-1">{{
					button.text | titlecase
				}}</span>
				<mat-icon
					[svgIcon]="button.icon"
					*ngIf="button.iconAfterText"
				></mat-icon>
			</span>
		</button>
	</div>
	<mat-menu #menu="matMenu" class="rounded-xl">
		<button
			*ngFor="let menu of matMenu"
			(click)="menuItemClicked(menu)"
			mat-menu-item
		>
			<mat-icon [svgIcon]="menu.icon"></mat-icon>
			<span class="action-menu-text font-medium">{{ menu.name }}</span>
		</button>
	</mat-menu>

	<mat-menu #importMenu="matMenu" class="rounded-xl">
		<button
			routerLink=""
			*ngFor="let menu of importMenus"
			mat-menu-item
			(click)="importEvent.emit(menu)"
		>
			<mat-icon [svgIcon]="menu.icon"></mat-icon>
			<span class="action-menu-text font-medium">{{ menu.name }}</span>
		</button>
	</mat-menu>
</div>
