import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthService } from 'app/core/auth/auth.service';
import { ProspectsService } from 'app/services/prospects.service';
import { TokenInterceptor } from '../interceptor/token.interceptor';

@NgModule({
	imports: [HttpClientModule],
	providers: [
		AuthService,
		ProspectsService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true,
		},
	],
})
export class AuthModule {}
