import {
	Component,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject } from 'rxjs';
import { PanelNotificationService } from 'app/services/panelNotification.service';
import { NotificationService } from 'app/services/notification.service';
import { INotification } from 'app/core/interfaces/INotification';

@Component({
	selector: 'notifications',
	templateUrl: './notifications.component.html',
	exportAs: 'notifications',
})
export class NotificationsComponent implements OnInit, OnDestroy {
	notificationDescription = {
		ImportProcessCompletedNotification: 'Import process completed',
		ImportProcessStartedNotification: 'Import process started',
		ImportProcessCreatedNotification: 'Import process created',
		ImportProcessStoppedNotification: 'Import process stopped',
	};
	@ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;

	notifications: INotification[] = [];
	unreadCount = 0;
	notificationPaginationConfig = {
		page: 1,
		total: 0,
		limit: 5,
	};
	@ViewChild('notificationsPanel')
	private _notificationsPanel: TemplateRef<any>;
	private _overlayRef: OverlayRef;
	private _unsubscribeAll: Subject<any> = new Subject<any>();

	constructor(
		private _overlay: Overlay,
		private _viewContainerRef: ViewContainerRef,
		private notificationService: PanelNotificationService,
		private _notificationService: NotificationService
	) {}

	ngOnInit(): void {
		this.getNotification();
	}

	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();

		// Dispose the overlay
		if (this._overlayRef) {
			this._overlayRef.dispose();
		}
	}

	openPanel(): void {
		// Return if the notifications panel or its origin is not defined
		if (!this._notificationsPanel || !this._notificationsOrigin) {
			return;
		}

		// Create the overlay if it doesn't exist
		if (!this._overlayRef) {
			this._createOverlay();
		}

		// Attach the portal to the overlay
		this._overlayRef.attach(
			new TemplatePortal(this._notificationsPanel, this._viewContainerRef)
		);
	}

	closePanel(): void {
		this._overlayRef.detach();
	}

	markAllAsRead(): void {
		this.notificationService.markAllAsRead().subscribe({
			next: (res) => {
				this.notifications.forEach((notification) => {
					notification.isRead = true;
				});
				this.calculateUnreadCount();
			},
			error: (err) => {
				this._notificationService.sendNotification(
					'error',
					err.error.message || 'Internal server error'
				);
			},
		});
	}

	toggleRead(notification: INotification) {
		notification.isRead = !notification.isRead;
		const status = {
			isRead: notification.isRead,
		};
		this.notificationService
			.changeNotificationStatus(notification._id, status)
			.subscribe({
				next: (res) => {
					this.calculateUnreadCount();
				},
				error: (err) => {
					this._notificationService.sendNotification(
						'error',
						err.error.message || 'Internal server error'
					);
				},
			});
	}

	delete(notification: INotification) {
		this.notificationService.deleteNotification(notification._id).subscribe({
			next: (res) => {
				const index = this.notifications.findIndex(
					(ele) => ele._id === notification._id
				);
				this.notifications.splice(index, 1);
				this.calculateUnreadCount();
			},
			error: (err) => {
				this._notificationService.sendNotification(
					'error',
					err.error.message || 'Internal server error'
				);
			},
		});
	}

	trackByFn(index: number, item: any): any {
		return item._id || index;
	}

	notiDate(date) {
		return new Date(date);
	}

	getNotification() {
		this.notificationService
			.getNotification(this.notificationPaginationConfig)
			.subscribe({
				next: (res) => {
					this.notifications = this.notifications.concat(...res.data.elements);
					this.notificationPaginationConfig.total = res.data.metadata.total;
					this.calculateUnreadCount();
				},
				error: (err) => {
					if (err.error.code !== 'TRIAL_OVER') {
						this._notificationService.sendNotification(
							'error',
							err.error.message || 'Internal server error'
						);
					}
				},
			});
	}

	calculateUnreadCount(): void {
		let count = 0;
		if (this.notifications && this.notifications.length) {
			count = this.notifications.filter(
				(notification) => !notification.isRead
			).length;
		}
		this.unreadCount = count;
	}

	private _createOverlay(): void {
		// Create the overlay
		this._overlayRef = this._overlay.create({
			hasBackdrop: true,
			backdropClass: 'fuse-backdrop-on-mobile',
			scrollStrategy: this._overlay.scrollStrategies.block(),
			positionStrategy: this._overlay
				.position()
				.flexibleConnectedTo(
					this._notificationsOrigin._elementRef.nativeElement
				)
				.withLockedPosition(true)
				.withPush(true)
				.withPositions([
					{
						originX: 'start',
						originY: 'bottom',
						overlayX: 'start',
						overlayY: 'top',
					},
					{
						originX: 'start',
						originY: 'top',
						overlayX: 'start',
						overlayY: 'bottom',
					},
					{
						originX: 'end',
						originY: 'bottom',
						overlayX: 'end',
						overlayY: 'top',
					},
					{
						originX: 'end',
						originY: 'top',
						overlayX: 'end',
						overlayY: 'bottom',
					},
				]),
		});

		// Detach the overlay from the portal on backdrop click
		this._overlayRef.backdropClick().subscribe(() => {
			this._overlayRef.detach();
		});
	}

	onNotificationScroll() {
		this.notificationPaginationConfig.page++;
		this.getNotification();
	}
}
