/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
	{
		id: 'dashboards.home',
		title: 'Home',
		type: 'basic',
		icon: 'heroicons_outline:home',
		link: '/dashboards/analytics',
	},
	{
		id: 'dashboards.prospect',
		title: 'Prospects',
		type: 'basic',
		icon: 'heroicons_outline:user-group',
		link: '/apps/prospects',
	},
	{
		id: 'dashboards.crm',
		title: 'CRM',
		type: 'basic',
		icon: 'heroicons_outline:chart-pie',
		link: '/apps/CRM',
	},
	{
		id: 'dashboards.campaign',
		title: 'Campaign',
		type: 'basic',
		icon: 'heroicons_outline:speakerphone',
		link: '/apps/campaigns',
	},
	{
		id: 'dashboards.template',
		title: 'Message Template',
		type: 'basic',
		icon: 'heroicons_outline:annotation',
		link: '/apps/templates',
	},
	{
		id: 'dashboards.setting',
		title: 'Settings',
		type: 'basic',
		icon: 'heroicons_outline:cog',
		link: '/apps/settings',
	},
];
