import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Urls } from '../core/urls';
import { Observable, Subject } from 'rxjs';
import {
	IAccountActivityData,
	IDailyQuotas,
	IUserData,
	IProcessData,
	IInvoiceData,
	IBillingDetails,
} from '../core/interfaces/ISetting';
import { IApiResponse, IPaginateResult } from '../core/interfaces/common';
import * as countrycitystatejson from 'countrycitystatejson';

@Injectable({
	providedIn: 'root',
})
export class SettingService {
	private countryData = countrycitystatejson;
	public isFromSubscribe = true;
	public isFormBillingDetails = false;
	public isPaymentDone = new Subject<boolean>();
	constructor(private _httpClient: HttpClient) {}

	getProfile(): Observable<IApiResponse<IUserData>> {
		return this._httpClient.get<IApiResponse<IUserData>>(`${Urls.user}/me`);
	}

	getAccountActivity(): Observable<IApiResponse<IAccountActivityData>> {
		return this._httpClient.get<IApiResponse<IAccountActivityData>>(
			`${Urls.user}/settings`
		);
	}

	updateAccountActivity(activityData): Observable<IAccountActivityData> {
		return this._httpClient.patch<IAccountActivityData>(
			`${Urls.user}/settings`,
			activityData
		);
	}

	restoreQuotas(defaultQuotas): Observable<IDailyQuotas> {
		return this._httpClient.patch<IDailyQuotas>(
			`${Urls.user}/restore/daily-quota`,
			defaultQuotas
		);
	}

	getProcesses(
		params
	): Observable<IApiResponse<IPaginateResult<IProcessData>>> {
		return this._httpClient.get<IApiResponse<IPaginateResult<IProcessData>>>(
			`${Urls.importProcesses}`,
			{ params }
		);
	}

	getInvoices(params): Observable<IApiResponse<IPaginateResult<IInvoiceData>>> {
		return this._httpClient.get<IApiResponse<IPaginateResult<IInvoiceData>>>(
			`${Urls.invoice}`,
			{ params }
		);
	}

	cancelSubscription() {
		return this._httpClient.delete(`${Urls.subscription}/cancel`);
	}
	reSubscription() {
		return this._httpClient.patch(`${Urls.subscription}/re-activate`, {
			token: localStorage.getItem('token'),
		});
	}
	getBillingDetails() {
		return this._httpClient.get<IApiResponse<IPaginateResult<IBillingDetails>>>(
			`${Urls.billingDetails}`
		);
	}
	saveBillingDetails(data) {
		return this._httpClient.put<IApiResponse<IPaginateResult<IBillingDetails>>>(
			`${Urls.billingDetails}`,
			data
		);
	}
	getCountries() {
		return this.countryData.getCountries();
	}
	getStatesByShort(countryShotName: string) {
		return this.countryData.getStatesByShort(countryShotName);
	}

	upgradePlan() {
		return this._httpClient.post(`${Urls.upgradePlan}`, {});
	}
	getSubscriptionDetails(id: string) {
		return this._httpClient.get(`${Urls.subscription}/${id}`);
	}
	getUserPlanDetails() {
		return this._httpClient.get(`${Urls.subscription}`);
	}

	exchangeToken() {
		return this._httpClient.post(`${Urls.tokenExchange}`, {
			token: localStorage.getItem('token'),
		});
	}
}
