import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackBarComponent } from 'app/layout/common/snack-bar/snack-bar.component';

@Injectable({ providedIn: 'root' })
export class NotificationService {
	config: MatSnackBarConfig = {
		horizontalPosition: 'end',
		verticalPosition: 'bottom',
		duration: 3000,
		panelClass: ['my-snackbar-class'],
	};
	constructor(private snackBar: MatSnackBar) {}

	sendNotification(type, data) {
		this.snackBar.dismiss();
		switch (type) {
			case 'error':
				this.snackBar.openFromComponent(SnackBarComponent, {
					data: {
						title: 'ERROR!',
						message: data,
						class: 'error',
						type: 'error',
					},
					...this.config,
				});
				break;
			case 'success':
				this.snackBar.openFromComponent(SnackBarComponent, {
					data: {
						title: 'SUCCESS!',
						message: data,
						class: 'success',
						type: 'success',
					},
					...this.config,
				});
				break;
			case 'warning':
				this.snackBar.openFromComponent(SnackBarComponent, {
					data: {
						title: 'WARNING!',
						message: data,
						class: 'warning',
						type: 'warning',
					},
					...this.config,
				});
				break;
			case 'info':
				this.snackBar.openFromComponent(SnackBarComponent, {
					data: {
						title: 'INFO!',
						message: data,
						class: 'info',
						type: 'info',
					},
					...this.config,
				});
				break;
		}
	}
}
