<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full">
	<!-- Content -->
	<div class="flex flex-col flex-auto">
		<!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
		<router-outlet *ngIf="true"></router-outlet>
	</div>
</div>
