import { ActivitiesMockApi } from 'app/mock-api/pages/activities/api';
import { AnalyticsMockApi } from 'app/mock-api/dashboards/analytics/api';
import { AuthMockApi } from 'app/mock-api/common/auth/api';
import { ContactsMockApi } from 'app/mock-api/apps/contacts/api';
import { NavigationMockApi } from 'app/mock-api/common/navigation/api';
import { NotificationsMockApi } from 'app/mock-api/common/notifications/api';
import { UserMockApi } from 'app/mock-api/common/user/api';

export const mockApiServices = [
	ActivitiesMockApi,
	AnalyticsMockApi,
	AuthMockApi,
	ContactsMockApi,
	NavigationMockApi,
	NotificationsMockApi,
	UserMockApi,
];
