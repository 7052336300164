<div class="dialog-container">
	<div
		class="dialog-header"
		[ngClass]="{
			'regular-header': !data.isDelete,
			'delete-header': data.isDelete
		}"
	>
		<span class="text-3xl font-semibold tracking-tight leading-8">{{
			data.headerTitle
		}}</span>
		<mat-icon
			*ngIf="!data.freeTrialOver"
			class="cursor-pointer"
			svgIcon="mat_solid:close"
			(click)="closeModal()"
		></mat-icon>
	</div>
	<div class="dialog-body">
		<ng-template
			[ngTemplateOutlet]="!data.isDelete ? data.template : deleteTemplate"
		></ng-template>
	</div>
</div>

<ng-template #deleteTemplate>
	<div
		class="font-medium text-lg flex items-center justify-center delete-template-div"
	>
		Are you sure you want to delete {{ data.deletedText }} ?
	</div>
	<mat-dialog-actions class="flex justify-end">
		<button
			mat-stroked-button
			color="accent"
			class="cancel-button"
			[mat-dialog-close]="false"
			mat-dialog-close
		>
			Cancel
		</button>
		<button
			[mat-dialog-close]="true"
			cdkFocusInitial
			mat-flat-button
			color="primary"
			class="delete-button ml-2"
		>
			Delete
		</button>
	</mat-dialog-actions>
</ng-template>
