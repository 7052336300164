import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
	standalone: true,
	selector: 'app-no-result-found',
	templateUrl: './no-result-found.component.html',
	styleUrls: ['./no-result-found.component.scss'],
	imports: [NgIf],
})
export class NoResultFoundComponent {
	@Input() imageSource;
	@Input() message;
}
