import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'stateFilter',
})
export class StateFilterPipe implements PipeTransform {
	transform(states: string[], searchText: string): string[] {
		if (!states || !searchText) {
			return states;
		}

		searchText = searchText.toLowerCase();

		return states.filter((country) =>
			country.toLowerCase().includes(searchText)
		);
	}
}
